/* Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. */
/* SPDX-License-Identifier: MIT-0 */

:root {
  --video-width: 88.4rem;
  --raise-hand-margin: 1.2rem;
}

.video-elem {
  top: 0;
  background: #000;
}

.overlay-raise-hand {
  color: var(--color-text-inverted);
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  background: var(--color-bg-secondary);
  border-radius: var(--radius);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: var(--raise-hand-margin);
}

.overlay-raise-hand-icon {
  width: 2.4rem;
  height: 2.4rem;
  fill: currentColor;
  display: flex;
  align-items: center;
  padding: 0.4rem;
}

.overlay-raise-hand-icon > svg {
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.player-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  /* Smaller Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  /* Small Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  /* Large Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  /* Large Screens */
  :root {
    --video-width: 64rem;
  }
}
